import React, { Component } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PageComponents from '@components/PageComponents'
import PostList from '@components/PostList'
import Pagination from '@components/Pagination'
import Footer from '@components/Footer'
import * as S from '@styles/components/Archive'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export default class ProjectsPage extends Component {
  constructor(props) {
    super(props)
    this.useFlyout = false
  }

  render() {
    const { data, pageContext } = this.props
    const {
      allWordpressWpProject: { edges: projects },
      allWordpressWpPlatform: { edges: platforms },
      wordpressPage: { yoast_meta: meta, ...page },
    } = data

    page.acf.hero.type = 'basic'
    if (page.acf.hero && !page.acf.hero.heading) {
      page.acf.hero.heading = page.title
    }

    return (
      <Container page={page}>
        <SEO {...meta} path={page.path} />
        {page.acf.hero && <Hero {...page.acf.hero} {...page} />}
        <main>
          {page.acf.content_page && (
            <article>
              <PageComponents components={page.acf.content_page} />
            </article>
          )}
          <S.PageWrapper>
            <S.Archive>
              <PostList
                type="project"
                posts={projects}
                categories={platforms}
                useFlyout={this.useFlyout}
              />
              <Pagination pageContext={pageContext} pathPrefix="/projects" />
            </S.Archive>
          </S.PageWrapper>
        </main>
        <Footer />
      </Container>
    )
  }
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({
    allWordpressWpProject: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectsQuery($limit: Int!, $skip: Int!) {
    allWordpressWpProject(
      sort: { fields: menu_order }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    allWordpressWpPlatform(filter: { count: { gt: 0 } }) {
      edges {
        node {
          id
          name
          path
        }
      }
    }
    wordpressPage(slug: { eq: "projects" }) {
      title
      acf {
        hero {
          ...HeroFragment
        }
        content_page {
          __typename
          ...AccordionBlockComponentFragment
          ...BannerComponentFragment
          ...CardsComponentFragment
          ...FeaturedProjectsComponentFragment
          ...FormComponentFragment
          ...ImageComponentFragment
          ...ImageBlocksComponentFragment
          ...LogoGridComponentFragment
          ...ListBlockComponentFragment
          ...SliderComponentFragment
          ...TeamComponentFragment
          ...TestimonialsComponentFragment
          ...TextComponentFragment
          ...TextWithImagesFragment
          ...TilesComponentFragment
        }
      }
      yoast_meta {
        ...metaData
      }
    }
  }
`
